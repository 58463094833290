<template>
  <div class="details">
      <div class="details-box">
       <div class="details_title">中国人民大学特色社会实践活动模式</div>
       <div>
           社会实践是落实高校“立德树人”根本任务的重要载体。从办学实践来看，实践育人已经成为高校人才培养体系的重要组成部分，以实现对学生的思想发展引领、国情社情教育、专业能力发展、综合素质提升、团队意识培养等。习近平总书记在全国高校思 想政治工作会议上强调，做好高校思想政治工作， 要因事而化、因时而进、因势而新，要广泛开展各 类社会实践。
       </div>
       <div>
           中国人民大学作为中国共产党创办的新中国第一所新型正规大学，具有从实际出发、理论联系实际的办学传统，高度重视实践育人工作，其社会实践活动也独具特色。
       </div>
       <div>
           中国人民大学的社会实践活动主要是以本科生为主体进行社会实践。这种社会实践模式主要立足于中国人民大学于2013年实施的本科人才培养路线图，其旨在面向本科教育教学推进“研究型学习制度体系”建设。中国人民大学本科人才培养路线图以“立德树人”为理念，通过精实课程等八项制度，完善人才培养方式，促进学生学习真知识、发现真问题、开展真研究、提出真见解，致力于培养具有厚重品质，始终奋进在时代前列，能在各行各业发挥引领作用的“国民表率，社会栋梁”。而这一路线图中最重要的模块之一，便是本科生的社会实践培养。本文将从实践模式、过程管理、成果转化三个方面来探求中国人民大学的特色社会实践活动模式。
       </div>
       <h3>一、实践模式  </h3>
       <div>中国人民大学的社会实践分有不同的实践模块和项目，其主要分为三部分：第一个模块是暑假的“千人百村”，第二块是寒假的“街巷中国”，第三块是针对思政课设计的“问道新时代”。其中，前两个模块分别针对农村和城市，其模式主要以“问卷调研”这种统一的社会调研形式展开。</div>
       <div>首先，由各个学院的教授们设计和总结出一整套兼顾学术性与实践性的专业问卷，其调查问卷的主题也均由教授们来拟定。教授们会根据每年的时事热点确定本次的社会实践主题，比如公共管理学院、经济学院以及农业与农村发展学院的教授们会针对各自的专业领域进行热点的考察与筛选，将最具影响力、具有重大意义的热点放在首位，经过专家研讨会后敲定最适合学生进行社会实践的主题。</div>
       <div>其次，开展社会实践的地点则是由统计学院随机进行抽取，比如面向农村的“千人百村”项目，就是由统计学院从时事热点地区随机抽取某一村落作为调研对象，以此来保证调研的多样性与有效性。而面向街道的“街巷中国”项目则多以学生家乡所在街道为调研对象，并以深度访谈和问卷调查相结合的模式进行，也在一定程度上保证了调研对象的科学多样性。</div>
       <div>最后，在问卷的主题、内容以及调研对象确定后，学生则围绕调查问卷来展开展社会实践活动，即学生们带着问卷、问题，直接下到基层去参与实践活动。一般分为学生组队形成实践团体，以及以个人为单位进行调研活动。在调研实践活动中，学生须完成“规定动作”，即必须完成问卷调研，完成一定数量的问卷；除此之外，学生还可以形成自己的“自选动作”，即完成规定任务的方案可以自由选择，且在规定任务之外，学生自己还可以根据自身情况选择性地去了解更多内容，这是激发学生自主性的关键一环，也避免了实践活动内容的千篇一律。</div>
       <div>中国人民大学社会实践活动的整个模式，是在专业性框架内去指导学生进行社会实践，其中又给予学生自主选择的余地，在充分保证社会实践的有效性和价值性上，充分激发学生的自主性和创造性，可以说是一套“静中有动，动静结合”的实践模式。</div>
       <h3>二、过程管理</h3>
       <div>
           中国人民大学社会实践活动是在校团委社会实践部主导下进行的，校团委对社会实践的每一个环节严格把控，力求将社会实践的效用发挥到最大，让每一位学生都能真正地获得参与感，收获成长。
       </div>
       <div>首先，中国人民大学校团委社会实践部发布“社会实践活动学生招募公告”后，将会开放微人大网上报名系统，在校学生须登陆报名系统提交相关信息进行报名。报名截止后，校团委联合相关部门，根据报名学生所选调研地数量、是否服从调剂进行遴选，综合学生的性别、年纪、专业、生源地等基本信息进行组队，从而最终确定入选实践团的人数，并由校团委社会实践部发布入选结果公告，公布社会调研活动入选学生名单。</div>
       <div>其次，校团委社会实践部会举办立项见面会，组织实践团员及联系人见面，介绍说明本次社会调研活动的结项要求以及学校学生社会实践相关政策保障、安全规定、补贴标准等一系列问题。见面会结束后，校团委社会实践部会择日举办培训会，邀请社会科学研究领域的老师向实践团员介绍调研流程，指导调研技巧，讲解调研问卷。随后，校学生社会实践服务中心会协助校团委社会实践部开展物资发放工作，实践团员有序前往规定地点领取调研问卷、实践旗、实践服等实践物资。</div>
       <div>在调研过程中，校团委社会实践部、学生社会实践服务中心委派的联系人将跟进各实践成员活动进展、传达通知；提醒各实践成员按照社会实践项目的结项要求和培训中提出的相关要求，及时开展问卷调查和资料搜集工作，并注意保留活动过程中照片、视频等各种结项材料。各实践成员在调研过程中须与联系人保持紧密联系，可自愿向联系人提交反映活动进展的照片、通讯稿等材料，也可通过“第二课堂”系统撰写实践日志、发布实践照片、分享实践感悟。</div>
       <div>实践过程中的经费参照固定标准进行正常实报实销，比如“千人百村”项目中，每位学生的交通费不可超过八百元人民币，“街巷中国”项目中，因为其“就近调研”的性质，每位学生可获得二百元人民币的劳务费。</div>
       <div>若突发客观不可控因素时，实践成员可申请更换调研地，但须第一时间向联系人说明情况并填写《调研地更换申请表》。校团委将根据相关情况，为实践成员安排重新抽样后的调研地区。实践成员不得自行调整调研地点。</div>
       <div>最后，实践活动结束时，校团委对各实践成员提交的结项材料的真实性、规范性、完整性进行核查，并做出“结项”或“不予结项”的决定。校团委对“结项” 所需材料也做了相关规定，要求各项材料须分别保存至相关命名的子文件夹（“调研报告”、“调研照片”、“专题行动”），将三个子文件夹保存到一个总文件夹下，并将总文件夹规范命名为“省级行政区名-地市级行政区名-区/县级行政区名-街道-姓名-学号”，压缩后须及时在“中国人民大学第二课堂”平台上提交。而非必需的结项材料将作为评优的重要参考之一，活动通讯与实践总结等过程材料可在“中国人民大学第二课堂”系统中随时提交，其他非必需材料需在申请结项时保存至子文件夹（“非必需材料”）并入总文件夹中统一提交。审核工作完成后，结项结果将于“人大社会实践”公众号及人大青年网公布。</div>
       <div>校团委对“不予结项”的情况也做出了相关规定，如在正式立项后，因个人原因退出或未全程参与实地调研活动的；未按要求参加培训考核的；申报、立项、培训、调研、结项过程中存在弄虚作假行为的；调研报告存在学术不规范行为的；以及未在规定时间内按要求提交结项材料的。对以上诸类，校团委均会做出“不予结项”的决定。“不予结项”的同学，两年内不再接受报名参与学校组织开展的各项社会实践活动。</div>
       <h3>三、成果转化</h3>
       <div>中国人民大学社会实践活动的成果转化可从学生结项成果转化与最终的社会影响两方面来探讨。</div>
       <div>参与社会实践活动并结项成功的实践团成员在获得实践学分之外，均有资格参与学生社会实践评优、推优，并获得相关保障与支持。校团委设置的奖项分别为“综合奖”和“专项奖”。在“综合奖”的评选中，校团委将依据实践成员调研过程中或结项提交的各项材料进行综合评审，设置“优秀实践成员”、“杰出实践成员”、“最佳实践成员”的奖项，并给予奖励。在“专项奖”的评选中，校团委将依据实践成员调研过程中的突出表现设置专项奖，并给予奖励。而学生在实践过程中所写作的活动通讯稿、实践总结，拍摄的微电影、纪录片、vlog，以及各级党政领导批示的调研报告，政府和企事业单位出具的成果采纳证明等均可作为加分项参与奖项评选。</div>
       <div style=" margin-bottom: 30px;">在社会影响方面，中国人民大学早期开设有专门中国人民大学社会实践官网以定期发布、分享各项社会实践活动；现在其宣传途径则主要以公众号为主。中国人民大学社会实践公众号名为“人大社会实践”，是中国人民大学学生社会实践服务中心官方微信号，致力于发布最新鲜的实践信息，讲述最真实的实践故事，传播最人大的实践精神。人大社会实践公众号主要分为三大模块，分别为“实践项目”“实践材料”与“千人百村”。其将“千人百村”单独划为一整个模块，是为了突出“千人百村”社会实践活动的重要性及其“与时俱进”“经久不衰”的特点，可以说，“千人百村”是中国人民大学社会实践的“招牌项目”，所以需单列突出。“实践项目”模块中，其内容包含有“街巷中国”“千人百村”“追寻改革的足迹”“寻根人大青年行”以及“数说2018”等几个部分，均详细介绍了每个实践项目的概况、调研过程及成果。“实践材料”模块中，则包含有“实践宝典”与“实践认证”，以发布公众号文章与分享“百度云盘”文件的方式助力人大学子了解和参与社会实践。最后的“千人百村”模块则是学生的互动版区，以“我要分享”“我要吐槽”“信息报备”等为实践团成员提供了一个互相交流的平台。</div>     
      </div>
      <bottom></bottom>
  </div>
</template>

<script>
import bottom from '../components/bottom'
export default {
  name: 'details',
  components: {
    bottom
  },
}
</script>
<style lang="scss" scoped>
.details{
    padding: 50px 30px;
    padding-bottom: 0;
    background: #fff;
    overflow: hidden;
    margin-top: 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    z-index: 1000000;
    overflow: auto;
    .details-box{
        width: 1200px;
        margin: 0 auto;
    }
}
.details div{
    text-indent: 2em;
    font-size: 16px;
    text-align: left;
    line-height: 30px;
}
.details h3{
    text-align: left;
}
.details_title{
    text-align: center !important;
    font-size: 36px !important;
    margin: 30px 0 !important;
    margin-bottom: 50px !important;
}
</style>
